import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/image/Check_Big.svg'
import _imports_1 from '@/assets/image/Close_MD.svg'


const _withScopeId = (n: any) => (_pushScopeId("data-v-c795d8d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "scores-container" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "inner" }
const _hoisted_4 = { class: "icon-check" }
const _hoisted_5 = {
  key: 0,
  src: _imports_0
}
const _hoisted_6 = {
  key: 1,
  src: _imports_1
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questions, (num, questionId) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["tab-score", _ctx.isCorrect(questionId, _ctx.questions) ? 'correct' : 'incorrect']),
        key: `${num}-${questionId}`
      }, [
        _createVNode(_component_el_tooltip, {
          class: "box-item",
          effect: "light",
          placement: "top",
          disabled: _ctx.disabledTooltip
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", {
              innerHTML: _ctx.getTooltipHtml(questionId)
            }, null, 8, _hoisted_2)
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, [
                (_ctx.isCorrect(questionId, _ctx.questions))
                  ? (_openBlock(), _createElementBlock("img", _hoisted_5))
                  : (_openBlock(), _createElementBlock("img", _hoisted_6))
              ]),
              _createElementVNode("span", null, _toDisplayString(_ctx.label(questionId)), 1)
            ])
          ]),
          _: 2
        }, 1032, ["disabled"])
      ], 2))
    }), 128))
  ]))
}